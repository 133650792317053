<template>
  <div class="addDiscussion">
    <div class="section-form">
      <div class="section-item">
        <div class="section-item--label">姓名</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.userName"
            disabled
            inputAlign="right"
            placeholder="请填写姓名"
          ></v-input>
        </div>
      </div>
      <div class="section-item radio">
        <div class="section-item--label">是否匿名发布</div>
        <div class="section-item--control">
          <v-radio-group
            :radioObj="cryptonymOps"
            :value.sync="form.isAnonymity"
          ></v-radio-group>
        </div>
      </div>
      <div class="section-item">
        <div class="section-item--label">议题标题</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.title"
            inputAlign="right"
            placeholder="请输入标题"
          ></v-input>
        </div>
      </div>
      <div class="section-item textarea">
        <div class="section-item--label">议题内容</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.content"
            placeholder="请写下您的议事内容"
            type="textarea"
            :maxlength="500"
            :showWordLimit="true"
          ></v-input>
        </div>
      </div>

      <div class="section-item select">
        <div class="section-item--label">投票类型选择</div>
        <div class="section-item--control" @click="clickPicker('voteType')">
          <span>{{ voteTypeMap[form.voteType] || "" }}</span>
          <img src="./img/arrow_bottom.png" alt="" />
        </div>
      </div>

      <div class="section-item upload">
        <div class="section-item--label">议题图片</div>
        <div class="section-item--control">
          <div class="upload">
            <v-upload
              :imgUrl.sync="form.imageUrls"
              :activityPicture.sync="form.imageUrls1"
              ref="upload"
              :maxCount="9"
            ></v-upload>
            <p>注：最多可上传9张图片</p>
          </div>
        </div>
      </div>

      <div class="section-item date">
        <div class="section-item--label">公示开始时间</div>
        <div
          class="section-item--control"
          @click="clickPicker('publicityStartDate')"
        >
          <span>{{ form.publicityStartDate }}</span>
          <!-- <img src="./img/arrow_bottom.png" alt="" /> -->
        </div>
      </div>

      <div class="section-item date">
        <div class="section-item--label">公示结束时间</div>
        <div
          class="section-item--control"
          @click="clickPicker('publicityEndDate')"
        >
          <span>{{ form.publicityEndDate }}</span>
        </div>
      </div>

      <div class="section-item date">
        <div class="section-item--label">投票开始时间</div>
        <div
          class="section-item--control"
          @click="clickPicker('voteStartDate')"
        >
          <span>{{ form.voteStartDate }}</span>
        </div>
      </div>

      <div class="section-item date">
        <div class="section-item--label">投票结束时间</div>
        <div class="section-item--control" @click="clickPicker('voteEndDate')">
          <span>{{ form.voteEndDate }}</span>
        </div>
      </div>

      <div class="section-item date">
        <div class="section-item--label">监票开始时间</div>
        <div
          class="section-item--control"
          @click="clickPicker('monitorStartDate')"
        >
          <span>{{ form.monitorStartDate }}</span>
        </div>
      </div>

      <div class="section-item date">
        <div class="section-item--label">监票结束时间</div>
        <div
          class="section-item--control"
          @click="clickPicker('monitorEndDate')"
        >
          <span>{{ form.monitorEndDate }}</span>
        </div>
      </div>

      <div class="section-item signcCanvas">
        <div class="section-item--label">
          <span>电子签名</span>
          <div class="reset" @click="handleReset">
            <span>重置</span>
          </div>
        </div>
        <div class="section-item--control">
          <div class="sign">
            <img v-if="!isShowsignCanvas" :src="form.esignature" alt="" />
            <signCanvas
              v-else
              ref="esign"
              :width="614"
              :height="242"
              :isCrop="isCrop"
              :lineWidth="lineWidth"
              :lineColor="lineColor"
              :bgColor.sync="bgColor"
            />
          </div>
        </div>
      </div>

      <div class="section-item radio">
        <div class="section-item--label">电子签名是否公开</div>
        <div class="section-item--control">
          <v-radio-group
            :radioObj="publicOps"
            :value.sync="form.signatureIsOpen"
          ></v-radio-group>
        </div>
      </div>

      <div class="surebtn" @click="submitBefore">
        <div class="btn">发布</div>
      </div>
    </div>

    <v-picker
      :columns="columnsOps"
      :valueShow="pickerShow"
      valueKey="text"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>

    <v-dateTimePicker
      :value="dateTime"
      :valueShow.sync="dateTimeShow"
      :isAuto="true"
      type="datetime"
      title="选择完整时间"
      @confirmPicker="confirmDate"
    ></v-dateTimePicker>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { base64toFile } from "@/utils/utils.js";
import { addInfoURL, userInfoURL, uploadURL } from "./api";
import { voteTypeMap, setVoteTypeOps } from "./map";
import signCanvas from "@/components/bussiness/signCanvas";

export default {
  name: "addDiscussion",
  components: {
    signCanvas,
  },
  data() {
    return {
      cryptonymOps: [
        { name: 1, value: "是" },
        { name: 0, value: "否" },
      ],
      publicOps: [
        { name: 1, value: "公开" },
        { name: 0, value: "不公开" },
      ],
      voteTypeMap,
      voteTypeOps: setVoteTypeOps(),
      userInfo: {},
      form: {
        userName: "",
        isAnonymity: 1,
        title: "",
        content: "",
        voteType: 1,
        imageUrls: "",
        imageUrls1: "", // 为适配v-upload组件，用于记录imageUrls变化后的值
        publicityStartDate: "",
        publicityEndDate: "",
        voteStartDate: "",
        voteEndDate: "",
        monitorStartDate: "",
        monitorEndDate: "",
        esignature: "", // 签名图片
        signatureIsOpen: 1,
      },
      /* sign-canvas */
      isShowsignCanvas: false,
      isCrop: false,
      lineWidth: 6,
      lineColor: "#979797",
      bgColor: "#D8D8D8",
      type: "", // 字段名 用于picker判断值该赋给哪个字段
      /* v-picker */
      columnsOps: setVoteTypeOps(),
      pickerShow: false,
      /* v-dateTimePicker */
      dateTime: new Date(),
      dateTimeShow: false,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getuserInfo();
    },
    // 获取用户信息
    async getuserInfo() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(userInfoURL, { params: params });
      if (res.code === 200) {
        this.userInfo = res.data;
        this.form.userName = this.userInfo.userName;
        this.form.esignature = this.userInfo.esignature;
        // 签名展示图片还是签名组件
        this.isShowsignCanvas = !this.form.esignature;
      }
    },

    // 清空签名
    handleReset() {
      this.isShowsignCanvas = true;
      this.form.esignature = "";
      this.$nextTick(() => {
        this.$refs.esign.reset();
      });
    },
    submitBefore() {
      if (!this.valid()) {
        return;
      }
      if (!this.isShowsignCanvas) {
        // 已存在签名图片,直接提交
        this.submit();
      } else {
        // 未存在签名图片
        this.handleGenerate();
      }
    },
    /* signCanvas start */
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res;
          this.uploadImg(base64toFile(this.resultImg));
        })
        .catch((err) => {
          // alert(err); // 画布没有签字时会执行这里 'Not Signned'
          // if (err == "Not Signned") {
          //   this.$toast("未签名");
          // } else {
          this.$toast(err);
          // }
        });
    },
    // 签名转为图片
    async uploadImg(file) {
      var formData = new FormData();
      formData.append("file", file);
      let res = await this.$axios.post(uploadURL, formData);
      if (res.code == 200) {
        this.form.esignature = res.data.link;
        this.submit();
      }
    },
    submit() {
      // if (!this.valid()) {
      //   return;
      // }
      this.$axios
        .post(addInfoURL, {
          type: 3, // 1-议题，2-业主大会，3-议题上会
          ...this.form,
          imageUrls: this.form.imageUrls1, // 适配v-upload组件
          houseId: this.houseId || this.communityId || undefined,
          optUserId: this.userId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success({
              message: "投票提交成功，待业委会、社区审核，3s后自动跳转投票厅",
              forbidClick: true,
              duration: 3000,
            });
            const _this = this;
            setTimeout(() => {
              _this.$toast.clear();
              _this.$router.replace({
                name: "voteHall",
              });
            }, 2500);

            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        });
    },

    clickPicker(type) {
      this.type = type;
      if (type == "voteType") {
        this.pickerShow = true;
      } else {
        this.dateTime = this.form[type];
        this.dateTimeShow = true;
      }
      //  else if (type == "publicityStartDate") {
      //   this.dateTime = this.form[type];
      //   this.dateTimeShow = true;
      // } else if (type == "publicityEndDate") {
      //   this.dateTime = this.form[type];
      //   this.dateTimeShow = true;
      // } else if (type == "voteStartDate") {
      //   this.dateTime = this.form[type];
      //   this.dateTimeShow = true;
      // } else if (type == "voteEndDate") {
      //   this.dateTime = this.form[type];
      //   this.dateTimeShow = true;
      // } else if (type == "monitorStartDate") {
      //   this.dateTime = this.form[type];
      //   this.dateTimeShow = true;
      // } else if (type == "monitorEndDate") {
      //   this.dateTime = this.form[type];
      //   this.dateTimeShow = true;
      // }
    },
    /* v-picker */
    clickPickOverlay() {
      this.pickerShow = false;
    },
    cancelPick() {
      this.pickerShow = false;
    },
    confirmPick(val) {
      this.form[this.type] = val.value;
      this.pickerShow = false;
    },
    /* v-dateTimePicker */
    confirmDate(val) {
      this.form[this.type] = moment(val).format("YYYY-MM-DD HH:mm:ss");
    },

    // 验证必填项
    valid() {
      const {
        userName,
        title,
        content,
        // imageUrls1,
        // signatureIsOpen,
        esignature,
        publicityStartDate,
        publicityEndDate,
        voteStartDate,
        voteEndDate,
        monitorStartDate,
        monitorEndDate,
      } = this.form;
      let message = undefined;
      if (userName === "") {
        message = "姓名不可为空";
      } else if (title === "") {
        message = "议题标题不可为空";
      } else if (content === "") {
        message = "议题内容不可为空";
      }
      //  else if (esignature === "") {
      //   message = "电子签名不可为空";
      // }
      else if (publicityStartDate === "") {
        message = "公示开始时间不能为空";
      } else if (publicityEndDate === "") {
        message = "公示结束时间不能为空";
      } else if (voteStartDate === "") {
        message = "投票开始时间不能为空";
      } else if (voteEndDate === "") {
        message = "投票结束时间不能为空";
      } else if (monitorStartDate === "") {
        message = "监票开始时间不能为空";
      } else if (monitorEndDate === "") {
        message = "监票结束时间不能为空";
      } else if (
        moment(publicityEndDate.replace(/-/g, "/")).isBefore(
          publicityStartDate.replace(/-/g, "/")
        )
      ) {
        message = "公示开始时间不能大于公示结束时间";
      } else if (
        moment(voteEndDate.replace(/-/g, "/")).isBefore(
          voteStartDate.replace(/-/g, "/")
        )
      ) {
        message = "投票开始时间不能大于投票结束时间";
      } else if (
        moment(monitorEndDate.replace(/-/g, "/")).isBefore(
          monitorStartDate.replace(/-/g, "/")
        )
      ) {
        message = "监票开始时间不能大于监票结束时间";
      } else if (
        moment(voteStartDate.replace(/-/g, "/")).isBefore(
          publicityEndDate.replace(/-/g, "/")
        )
      ) {
        message = "公示结束时间不能大于投票开始时间";
      } else if (
        moment(monitorStartDate.replace(/-/g, "/")).isBefore(
          voteEndDate.replace(/-/g, "/")
        )
      ) {
        message = "投票结束时间不能大于监票开始时间";
      }
      //  else if (imageUrls === "") {
      //   message = "请上传议题图片";
      // }
      if (message) {
        this.$toast({
          message,
          duration: 1000,
        });
      }
      return !message;
    },
  },
};
</script>
<style lang="less" scoped>
.addDiscussion {
  font-family: PingFangSC-Regular, PingFang SC;
  box-sizing: border-box;
  background: #f6f8fb;
  min-height: 100%;
  width: 100%;
  padding: 30px;
  padding-bottom: calc(
    30px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    30px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-form {
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    border-radius: 16px;
    padding: 32px 32px 50px 32px;
    background: #ffffff;
    .section-item {
      display: flex;
      &:nth-child(-n + 3) {
        border-bottom: 2px solid rgba(0, 0, 0, 0.04);
      }
      // border-bottom: 2px solid rgba(0, 0, 0, 0.04);
      .section-item--label {
        flex: 1;
        line-height: 88px;
        color: #333333;
        font-size: 30px;
        text-align: left;
        font-weight: 400;
        white-space: nowrap;
      }
      .section-item--control {
        max-width: 400px;
      }
      &.radio {
        .section-item--control {
          display: flex;
          align-items: center;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      &.textarea {
        display: block;
        .section-item--control {
          box-sizing: border-box;
          max-width: none;
          ::v-deep .van-cell {
            border-radius: 16px;
            background: rgba(0, 0, 0, 0.05);
          }
        }
      }
      &.upload {
        display: block;
        .section-item--control {
          box-sizing: border-box;
          max-width: none;
          // ::v-deep .van-cell {
          //   background: rgba(0, 0, 0, 0.05);
          // }
          ::v-deep .van-uploader {
            .van-uploader__preview {
              .van-uploader__preview-image {
                vertical-align: middle;
                width: 160px;
                height: 160px;
                img {
                  vertical-align: middle;
                  overflow: hidden;
                  margin: 0;
                  padding: 0;
                }
              }
              .van-uploader__preview-delete {
                width: 28px;
                height: 28px;
                border-radius: 0 0 0 24px;
                i {
                  position: absolute;
                  vertical-align: middle;
                  font-size: 32px;
                  top: 45%;
                  left: 55%;
                  transform: translateX(-50%) translateY(-50%) scale(0.5);
                }
              }
            }
            .van-uploader__upload {
              width: 160px;
              height: 160px;
            }
          }
          p {
            font-size: 28px;
            color: rgba(0, 0, 0, 0.5);
            line-height: 34px;
            margin-top: 20px;
            font-weight: 400;
          }
        }
      }
      &.signcCanvas {
        display: block;
        .section-item--label {
          display: flex;
          align-items: center;
          span {
            flex: 1;
          }
          .reset {
            font-size: 28px;
            color: #0578fa;
            letter-spacing: 4px;
          }
        }
        .section-item--control {
          box-sizing: border-box;
          max-width: none;
          .sign {
            margin-bottom: 12px;
            border-radius: 16px;
            overflow: hidden;
            img {
              width: 100%;
              height: 242px;
            }
          }
        }
      }
      &.select {
        .section-item--control {
          width: 400px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 28px;
          line-height: 40px;
          white-space: normal;
          word-break: break-all;
          word-wrap: break-word;
          color: rgba(0, 0, 0, 0.25);
          margin-left: 20px;
          img {
            margin-left: 8px;
            width: 28px;
            height: 16px;
            vertical-align: middle;
          }
        }
      }
      &.date {
        border-bottom: 2px solid rgba(0, 0, 0, 0.04);
        .section-item--control {
          width: 400px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 28px;
          line-height: 40px;
          white-space: normal;
          word-break: break-all;
          word-wrap: break-word;
          color: rgba(0, 0, 0, 0.25);
          margin-left: 20px;
          img {
            margin-left: 8px;
            width: 28px;
            height: 16px;
            vertical-align: middle;
          }
        }
      }
    }
    .surebtn {
      margin-top: 78px;
      width: 100%;
      display: flex;
      justify-content: center;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 492px;
        height: 74px;
        background: linear-gradient(270deg, #c4e2ff 0%, #9ccdff 100%);
        border-radius: 39px;
        border: 8px solid #ecf7ff;
        font-weight: bold;
        font-size: 30px;
        color: #ffffff;
        letter-spacing: 4px;
      }
    }
  }
}
</style>
